import React, { ReactElement } from "react";

import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";

import { motion } from "framer-motion";
import BackgroundImage from "gatsby-background-image";

// Component imports
import Text from "../components/Text";
import Layout from "../components/Layout";
import Section from "../components/Section";
import { useState } from "react";
import SEO from "../components/SEO";
function Jobs(): ReactElement {
    const data = useStaticQuery(graphql`
        query {
            allJobs: allGhostPost(
                filter: { primary_tag: { name: { eq: "jobs" } } }
                sort: { fields: created_at, order: DESC }
            ) {
                edges {
                    node {
                        title
                        excerpt
                        slug
                        tags {
                            name
                        }
                    }
                }
            }
            background: file(relativePath: { eq: "header_bg.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1920, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            page_bg: file(relativePath: { eq: "page_bg.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1920, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);

    const [Filter, setFilter] = useState("All");

    const tags = data.allJobs.edges.map(
        (item: { node: { tags: { name: string } } }) =>
            item.node.tags.map((tag) => tag.name)
    );

    const uniqueTags = [...new Set(tags.flat())];
    const filteredTags = uniqueTags.filter(function (e) {
        return e !== "jobs";
    });

    const allTags = ["All", ...filteredTags];
    const renderPost = (data: any) => {};
    return (
        <Layout>
            <SEO
                title="Jobs"
                description="Latest job offerings relating to SpaceDelta!"
            />
            <section className="h-newsHeaderSmall">
                <BackgroundImage
                    fluid={data.background.childImageSharp.fluid}
                    className="flex items-center justify-center h-full bg-center bg-cover"
                >
                    <motion.div
                        className="relative block py-5 text-center bg-backgroundAlpha md:py-10 xl:py-16 "
                        initial={{ width: 0, scale: 1.5, opacity: 0 }}
                        animate={{ width: "100%", scale: 1, opacity: 1 }}
                        transition={{ delay: 0.2, duration: 0.3 }}
                    >
                        <Text variant="h2">Work with us</Text>
                        <div
                            className="absolute z-10 border-2 border-gray-500 votesHeaderBox"
                            style={{
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                            }}
                        ></div>
                    </motion.div>
                </BackgroundImage>
            </section>
            {/* text-center focus:outline-none transition-all cursor-pointer
            duration-300 ease-easeInOutQuint w-full flex items-center
            justify-center h-16 lg:h-20 px-4 text-sm md:text-xl text-white
            font-semibold hover:bg-purple-600 hover:opacity-100 tracking-wider
            uppercase bg-black opacity-30 mb-3" */}
            <Section>
                <div className="space-y-8">
                    <Text variant="h2">Overview</Text>
                    <Text variant="p">
                        The SpaceDelta team is almost always looking for new
                        talent to join our team! Click into each position below
                        to find out more about it.
                    </Text>
                    <Text variant="p">There are a few ground rules:</Text>
                    <Text variant="p">
                        - You must be 16 or older. <br />
                        - You must have a working microphone.
                        <br /> - You must speak English.
                    </Text>
                    <Text variant="p">
                        If you have questions about the application, please make
                        a ticket in our Discord.
                    </Text>
                </div>
                <div className="block mt-10 text-2xl font-bold">
                    Open Positions
                </div>
                {/* <div className="block mt-5 text-lg">Filters</div> */}
                {/* {JSON.stringify(tags)} */}
                <div className="flex flex-wrap gap-5 mt-5">
                    {allTags.map((tag) => {
                        return (
                            <button
                                onClick={() => setFilter(tag)}
                                className={
                                    Filter === tag
                                        ? "hover:opacity-100 bg-black block py-4 px-8 text-xl font-semibold uppercase text-purple-400 transition-all duration-300 ease-easeInOutQuint opacity-100 rounded-lg scale-110"
                                        : "hover:opacity-100 opacity-50 bg-black block py-4 px-8 text-xl font-semibold uppercase transition-all duration-300 ease-easeInOutQuint"
                                }
                            >
                                {tag}
                            </button>
                        );
                    })}
                </div>
                {/* <h2 className=" text-xl font-semibold mt-10 text-gray-500">
                    {Filter}:
                </h2> */}
                <div className="mt-10">
                    <div className="grid grid-cols-3 border-2 bg-backgroundAlpha border-gray-400  my-5 relative mb-10 font-bold">
                        <div className="p-5 text-center text-xl ">Position</div>
                        <p className="p-5 text-center text-lg max-h-16 border-l-2 border-gray-500">
                            Department
                        </p>
                        <p className="p-5 text-center h-full text-lg max-h-24 border-l-2 border-gray-500">
                            Team
                        </p>
                    </div>

                    {data.allJobs.edges.map((post: any) => {
                        if (Filter !== "All") {
                            if (
                                !post.node.tags
                                    .map((tag: { name: string }) => tag.name)
                                    .includes(Filter)
                            ) {
                                return null;
                            }
                        }
                        return (
                            <Link href={`/jobs/${post.node.slug}`}>
                                <motion.div
                                    initial={{
                                        height: 0,
                                        scale: 1.5,
                                        opacity: 0,
                                    }}
                                    animate={{
                                        height: "100%",
                                        scale: 1,
                                        opacity: 1,
                                    }}
                                    transition={{ duration: 0.3 }}
                                    className="grid grid-cols-3 border-2 bg-backgroundAlpha border-gray-400 p-5 my-5 relative hover:bg-black transition-all duration-300 ease-easeInOutQuint"
                                >
                                    <div className="font-semibold text-xl hover:text-purple-600 text-[#C74CE1] transition-all cursor-pointer duration-300 ease-easeInOutQuint ">
                                        {post.node.title}
                                    </div>
                                    <p className="text-lg max-h-16 text-center">
                                        {post.node.tags[1]?.name &&
                                            post.node.tags[1].name}
                                    </p>
                                    <p className="text-lg max-h-24 text-right">
                                        {post.node.excerpt.length >= 100
                                            ? post.node.excerpt.substring(
                                                  0,
                                                  100
                                              ) + "..."
                                            : post.node.excerpt}
                                    </p>
                                </motion.div>
                            </Link>
                        );
                    })}
                </div>
            </Section>
        </Layout>
    );
}
export default Jobs;
